<template>
  <div class="zadi-outlit">
    <router-view />
    <!-- <div v-if="isLoading" class="loader">
      Loading...
    </div>
    <router-view v-else /> -->
  </div>
</template>

<script>
// import auth from '../helpers/auth';

export default {
  name: 'ZadiOutlit',
  // computed: {
  //   isLoading() {
  //     return auth.isLoading
  //   }
  // },
};
</script>

<!-- <style scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  width: 100%;
  min-height: 100dvh;
  color: white;
  background: red;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
}
</style> -->
